import { FeatureToggles, getFeatureConfigData, isFeatureToggleEnabled } from './toggles';
import type { FeatureTogglesQuery } from '../generated/types';
import type { HotelPage } from './constants';

export type TailoredConfig = Partial<{
  brandComponentMapper: Record<string, Record<ValuesOf<typeof HotelPage>, string[]>>;
  ctyhocnComponentMapper: Record<string, Record<ValuesOf<typeof HotelPage>, string[]>>;
  excludedCtyhocns: string[];
  includedBrandCodes: string[];
  includedCtyhocns: string[];
}>;

type GetTailoredComponentsArgs = {
  pageName: ValuesOf<typeof HotelPage>;
  brandCode: string;
  ctyhocn: string;
  featureConfigs: FeatureTogglesQuery['featureConfigs'] | null | undefined;
  featureToggles: FeatureTogglesQuery['featureToggles'] | null | undefined;
};

export function getTailoredComponents({
  pageName,
  brandCode,
  ctyhocn,
  featureConfigs = [],
  featureToggles = [],
}: GetTailoredComponentsArgs) {
  const isEnabled = isFeatureToggleEnabled({
    featureToggleName: FeatureToggles.OHWPR_Tailored_Experience,
    data: featureToggles,
  });

  if (!isEnabled) {
    return [];
  }

  const config: TailoredConfig = getFeatureConfigData({
    featureConfigName: FeatureToggles.OHWPR_Tailored_Experience,
    data: featureConfigs,
  })?.config;

  const { isBrandIncluded, isCtyhocnIncluded, isCtyhocnExcluded } = getTailoredFlags({
    brandCode,
    ctyhocn,
    config,
  });

  if (isCtyhocnExcluded) {
    return [];
  }

  const propertyComponents = config?.ctyhocnComponentMapper?.[ctyhocn]?.[pageName] || [];
  const brandComponents = config?.brandComponentMapper?.[brandCode]?.[pageName] || [];

  if (isCtyhocnIncluded && propertyComponents.length) {
    return propertyComponents;
  }

  if (isBrandIncluded && brandComponents.length) {
    return brandComponents;
  }

  return [];
}

export function getTailoredFlags({
  brandCode,
  ctyhocn,
  config,
}: Pick<GetTailoredComponentsArgs, 'brandCode' | 'ctyhocn'> & {
  config: TailoredConfig | null;
}) {
  const isBrandIncluded = Boolean(config?.includedBrandCodes?.includes(brandCode));
  const isCtyhocnIncluded = Boolean(config?.includedCtyhocns?.includes(ctyhocn));
  const isCtyhocnExcluded = Boolean(config?.excludedCtyhocns?.includes(ctyhocn));
  return { isBrandIncluded, isCtyhocnIncluded, isCtyhocnExcluded };
}
