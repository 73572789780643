import type { FeatureTogglesQuery } from '../generated/types';

export const FeatureToggles = {
  OHWPR_Tailored_Experience: 'OHWPR_Tailored_Experience',
  OHWPR_1757_Essential_Rooms: 'OHWPR_1757_Rooms',
  CP_6321_Events_URL_Change: 'CP-6321',
  OHWPR_2641_TabbedOverview: 'OHWPR_2641_TabbedOverview',
  OHWPR_3099_PlanYourStay: 'OHWPR_3099',
  OHWPR_3015_Featured_Amenities: 'OHWPR_3015_Featured_Amenities',
  OHWPR_3402_Stay_Tour_Video: 'OHWPR_3402_Stay_Tour_Video',
  OHWPR_2671_Virtual_Tours: 'OHWPR_2671_Virtual_Tours',
  NHCBP_5435_Embassy_Refresh: 'NHCBP-5435-EmbassyRefresh',
} as const;

export const featureToggles = Object.values(FeatureToggles);
export const featureConfigs = [FeatureToggles.OHWPR_Tailored_Experience];

export function isFeatureToggleEnabled({
  featureToggleName,
  data = [],
}: {
  featureToggleName: ValuesOf<typeof FeatureToggles>;
  data?: FeatureTogglesQuery['featureToggles'] | null;
}) {
  return Boolean(data?.find((t) => t.name === featureToggleName)?.enabled);
}

export function getFeatureConfigData({
  featureConfigName,
  data = [],
}: {
  featureConfigName: (typeof featureConfigs)[number];
  data?: FeatureTogglesQuery['featureConfigs'] | null;
}) {
  return data?.find((t) => t.name === featureConfigName) ?? null;
}
