import { useMemo } from 'react';
import { FeatureToggles } from '../helpers/toggles';
import { getHotelsRouteParams } from '../helpers/routing';
import { useFeatureToggleConfig } from './use-feature-toggles';
import { useRouter } from 'next/router';
import { usePropertyData } from './use-property-data';
import type { TailoredConfig } from '../helpers/getTailoredComponents';
import { getTailoredFlags } from '../helpers/getTailoredComponents';

export function useIsTailored() {
  const router = useRouter();
  const propertyData = usePropertyData();
  const brandCode = propertyData?.brandCode || '';
  const ctyhocn = getHotelsRouteParams(router).ctyhocn || '';
  const config = useFeatureToggleConfig<TailoredConfig>(FeatureToggles.OHWPR_Tailored_Experience);
  const { isBrandIncluded, isCtyhocnIncluded, isCtyhocnExcluded } = useMemo(
    () => getTailoredFlags({ brandCode, ctyhocn, config }),
    [brandCode, config, ctyhocn]
  );
  return isCtyhocnExcluded ? false : isBrandIncluded || isCtyhocnIncluded;
}
